@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");


@layer base {
  body {
   @apply font-[Poppins];
   background-color: var(--bg-color);
   color: var(--text-color);
  }
  li {
    @apply px-4;
    @apply cursor-pointer
  }
}

p {
  margin-bottom: 15px;
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    #758cbccc,
    hsla(234, 54%, 40%, 0.8)
  )!important;
}
#light {
  background-color: white;
}

#dark {
  background-color: #181818;
  
  
}

.switch {
  display:flex;
  width:100vw;
  height:100px;
  justify-content: flex-start;
  align-items: flex-start;
}

.switch label {
  margin: 10px;
  color: var(--text-color)
}

#light .switch label {
  color: black;
}

#dark .switch label {
  color: white;
  
}
